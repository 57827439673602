<template>
  <div>
    <my-header></my-header>
    <div class="table-box">
      <div>
        <span>客户信息</span>
      </div>
      <div class="detail">
        <div>
          <span>经纪人id:</span><span v-text="detail.agentUserId"></span>
        </div>
        <div><span>客户电话:</span><span v-text="detail.phone"></span></div>
        <div><span>客户姓名:</span><span v-text="detail.name"></span></div>
        <div><span>性别:</span><span v-text="detail.sex"></span></div>
        <div>
          <span>购买意愿程度:</span><span v-text="detail.status"></span>
        </div>
        <div><span>客户需求:</span><span v-text="detail.need"></span></div>
        <div><span>项目名称:</span><span v-text="detail.entryname"></span></div>
        <div><span>项目地址:</span><span v-text="detail.address"></span></div>
        <div>
          <span>预计最佳追踪时间:</span
          ><span v-text="detail.starttrackingtime"></span>
        </div>
        <div>
          <span>预计需求时间:</span><span v-text="detail.demandtime"></span>
        </div>
        <div>
          <span>采购单位:</span><span v-text="detail.purchasingUnit"></span>
        </div>
        <div><span>备注:</span><span v-text="detail.message"></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";
export default {
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    getItem() {
      this.detail = JSON.parse(this.$route.query.item);
      console.log(JSON.parse(this.$route.query.item));
      console.log(this.detail);
    },
  },
  created() {
    this.getItem();
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  margin-top: 140px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
  .detail {
    width: 100%;
    color: #666;
    border: 1px solid #ccc;
    div {
      padding: 5px;
    }
    div:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
    div > span:nth-child(1) {
      display: inline-block;
      text-align: right;
      width: 50%;
    }
    div > span:nth-child(2) {
      margin-left: 15px;
    }
  }
}
</style>